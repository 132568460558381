/**
 * Global Config
 */

const config = {};

// Domains
config.domains = {};
config.installationURLs = {};

/**
 * API Domain
 */
config.domains.api = "https://automate.silverlining.cloud/api";
config.installationURLs.bitbucket =
  "https://bitbucket.org/site/oauth2/authorize?client_id=a8txLVuZLZ3sUK4Z4T&response_type=code";
config.installationURLs.github =
  "https://github.com/apps/automate-silverlining-cloud/installations/new";

export default config;
