import { useAuthenticationContext } from "contexts/AuthenticationContext";
import { Navigate } from "react-router-dom";

export default function ProtectedRoute({ component }) {
  const authenticationState = useAuthenticationContext();
  let condition;
  let redirectURL;

  authenticationState === "loggedIn" ? (condition = true) : (condition = false);
  authenticationState === "inactive"
    ? (redirectURL = "/confirm-email")
    : (redirectURL = "/");

  return condition ? component : <Navigate to={redirectURL} replace />;
}
